import { api } from '@/api'
import {
  IGetCardOnboardingStepsResponse,
  IAssignAccountOwnerPayload,
  IAssignAccountOwnerResponse,
  IChangeVerifiedAddressesPayload,
  IGetFundingAccountResponse,
  IGetCardsRequest,
  ICreateCardRequest,
  IUpdateCardRequest,
  IGetVerifiedAddressesResponse,
  IDeleteVerifiedWalletPayload,
  ICreateVerifiedWalletPayload,
  IGetCardRequest,
  IChangeVerifiedAddressesPostOnboardingPayload,
  IFreezeCardRequest,
  IUnFreezeCardRequest,
  ICancelCardRequest,
  IGetCardTransactionsResponse,
  IGenerateWithdrawalRequest,
  IGenerateWithdrawalResponse,
  ISignGeneratedWithdrawalRequest,
  IAppendCardAnnoationRequest,
  IRemoveCardAnnotationRequest,
  IAppendTransactionAnnotationRequest,
  IRemoveTransactionAnnotationRequest,
  IGenerateVerifiedAddressesPayload,
  IAddVerifiedAddressesPayload
} from './cards.dto'
import { IOrganizationCard } from './cards.type'

export const cardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCardOnboardingSteps: builder.query<IGetCardOnboardingStepsResponse, { organizationId: string }>({
      query: ({ organizationId }) => ({
        url: `${organizationId}/card-onboarding`,
        method: 'GET'
      }),
      transformResponse: (res) => res.data,
      providesTags: ['card-onboarding']
    }),
    getOrganizationCards: builder.query<any, IGetCardsRequest>({
      query: ({ params }) => ({
        url: `${params.organizationId}/cards`,
        method: 'GET',
        params: params.search ?? {}
      }),
      transformResponse: (res) => res.data,
      providesTags: ['cards']
    }),
    getOrganizationCard: builder.query<IOrganizationCard, IGetCardRequest>({
      query: ({ params }) => ({
        url: `${params.organizationId}/cards/${params.id}`,
        method: 'GET'
      }),
      transformResponse: (res) => res.data,
      providesTags: ['cards']
    }),
    getOrganizationCardDetail: builder.query<any, IGetCardRequest>({
      query: ({ params }) => ({
        url: `${params.organizationId}/cards/${params.id}/detail`,
        method: 'GET'
      }),
      transformResponse: (res) => res.data,
      providesTags: ['cards']
    }),
    getCardProducts: builder.query<any, IGetCardsRequest>({
      query: ({ params }) => ({
        url: `${params.organizationId}/card-products`,
        method: 'GET',
        params: params.search ?? {}
      }),
      transformResponse: (res) => res.data,
      providesTags: ['cards']
    }),
    getCardFundingAccount: builder.query<IGetFundingAccountResponse, { organizationId: string }>({
      query: ({ organizationId }) => ({
        url: `${organizationId}/card-funding-accounts`,
        method: 'GET'
      }),
      transformResponse: (res) => res.data,
      providesTags: ['card-funding-accounts']
    }),
    generateWithdrawal: builder.mutation<IGenerateWithdrawalResponse, IGenerateWithdrawalRequest>({
      query: ({ params, body }) => ({
        url: `${params?.organizationId}/card-funding-accounts/generateWithdrawal`,
        method: 'POST',
        body
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ['card-funding-accounts']
    }),
    signGeneratedWithdrawal: builder.mutation<any, ISignGeneratedWithdrawalRequest>({
      query: ({ params, body }) => ({
        url: `${params?.organizationId}/card-funding-accounts/signWithdrawal`,
        method: 'POST',
        body
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ['card-funding-accounts']
    }),
    getVerifiedAddresses: builder.query<IGetVerifiedAddressesResponse, { organizationId: string }>({
      query: ({ organizationId }) => ({
        url: `${organizationId}/card-verified-addresses`,
        method: 'GET'
      }),
      transformResponse: (res) => res.data,
      providesTags: ['card-verified-addresses']
    }),
    getCardTransactions: builder.query<
      IGetCardTransactionsResponse,
      { params: { organizationId: string; page: number; size: number; cardIds?: string[]; search?: string } }
    >({
      query: ({ params }) => ({
        url: `${params.organizationId}/card-transactions`,
        method: 'GET',
        params: { page: params.page ?? 0, size: params.size ?? 10, cardIds: params.cardIds, search: params?.search }
      }),
      transformResponse: (res) => res.data,
      providesTags: ['cards']
    }),
    assignAccountOwner: builder.mutation<IAssignAccountOwnerResponse, IAssignAccountOwnerPayload>({
      query: ({ params, body }) => ({
        url: `${params.organizationId}/card-onboarding/steps/assign-account-owner/register-address`,
        method: 'POST',
        body
      }),
      transformResponse: (res) => res?.data,
      invalidatesTags: ['card-onboarding']
    }),
    createCard: builder.mutation<any, ICreateCardRequest>({
      query: ({ params, body }) => ({
        url: `${params.organizationId}/cards`,
        method: 'POST',
        body
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ['cards']
    }),
    updateCard: builder.mutation<any, IUpdateCardRequest>({
      query: ({ params, body }) => ({
        url: `${params.organizationId}/cards/${params.id}`,
        method: 'PATCH',
        body
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ['cards']
    }),
    generateVerifiedAddresses: builder.mutation<any, IGenerateVerifiedAddressesPayload>({
      query: ({ organizationId, body }) => ({
        url: `${organizationId}/card-onboarding/steps/verified-addresses/register-addresses/generate`,
        method: 'POST',
        body
      }),
      transformResponse: (res) => res.data
    }),
    addVerifiedAddresses: builder.mutation<any, IAddVerifiedAddressesPayload>({
      query: ({ organizationId, body }) => ({
        url: `${organizationId}/card-onboarding/steps/verified-addresses/register-addresses/send`,
        method: 'POST',
        body
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ['card-onboarding']
    }),
    changeVerifiedAddresses: builder.mutation<any, IChangeVerifiedAddressesPayload>({
      query: ({ params, body }) => ({
        url: `${params.organizationId}/card-onboarding/steps/verified-addresses/register-addresses`,
        method: 'POST',
        body
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ['card-onboarding']
    }),
    generateVerifiedAddressesPostOnboarding: builder.mutation<any, IGenerateVerifiedAddressesPayload>({
      query: ({ organizationId, body }) => ({
        url: `${organizationId}/card-verified-addresses/generate`,
        method: 'POST',
        body
      }),
      transformResponse: (res) => res.data
    }),
    addVerifiedAddressesPostOnboarding: builder.mutation<any, IAddVerifiedAddressesPayload>({
      query: ({ organizationId, body }) => ({
        url: `${organizationId}/card-verified-addresses/send`,
        method: 'POST',
        body
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ['card-verified-addresses']
    }),
    changeVerifiedAddressesPostOnboarding: builder.mutation<any, IChangeVerifiedAddressesPostOnboardingPayload>({
      query: ({ params, body }) => ({
        url: `${params.organizationId}/card-verified-addresses`,
        method: 'POST',
        body
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ['card-verified-addresses']
    }),
    createCardAnnotation: builder.mutation<any, IAppendCardAnnoationRequest>({
      query: ({ params, body }) => ({
        url: `${params.organizationId}/cards/${params.cardId}/annotations`,
        method: 'POST',
        body
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ['cards']
    }),
    deleteCardAnnotation: builder.mutation<any, IRemoveCardAnnotationRequest>({
      query: ({ params }) => ({
        url: `${params.organizationId}/cards/${params.cardId}/annotations/${params?.annotationId}`,
        method: 'DELETE'
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ['cards']
    }),
    appendTransactionAnnotation: builder.mutation<any, IAppendTransactionAnnotationRequest>({
      query: ({ params, body }) => ({
        url: `${params.organizationId}/card-transactions/${params.transactionId}/annotations`,
        method: 'POST',
        body
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ['cards']
    }),
    removeTransactionAnnotation: builder.mutation<any, IRemoveTransactionAnnotationRequest>({
      query: ({ params }) => ({
        url: `${params.organizationId}/cards/${params.transactionId}/annotations/${params?.annotationId}`,
        method: 'DELETE'
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ['cards']
    }),
    deleteVerifiedWallet: builder.mutation<any, IDeleteVerifiedWalletPayload>({
      query: ({ params }) => ({
        url: `${params.organizationId}/card-verified-addresses/${params?.id}`,
        method: 'DELETE'
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ['card-verified-addresses']
    }),
    createVerifiedWallet: builder.mutation<any, ICreateVerifiedWalletPayload>({
      query: ({ params }) => ({
        url: `${params.organizationId}/card-verified-addresses`,
        method: 'POST'
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ['card-verified-addresses']
    }),
    freezeCard: builder.mutation<any, IFreezeCardRequest>({
      query: ({ params }) => ({
        url: `${params.organizationId}/cards/${params.id}/freeze`,
        method: 'POST'
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ['cards']
    }),
    unfreezeCard: builder.mutation<any, IUnFreezeCardRequest>({
      query: ({ params }) => ({
        url: `${params.organizationId}/cards/${params.id}/unfreeze`,
        method: 'POST'
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ['cards']
    }),
    cancelCard: builder.mutation<any, ICancelCardRequest>({
      query: ({ params }) => ({
        url: `${params.organizationId}/cards/${params.id}/cancel`,
        method: 'POST'
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ['cards']
    }),
    syncCardTransactions: builder.mutation<any, any>({
      query: ({ params }) => ({
        url: `${params.organizationId}/card-transactions/sync`,
        method: 'POST'
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ['cards', 'card-funding-accounts']
    })
  })
})

export const {
  useGetCardOnboardingStepsQuery,
  useAssignAccountOwnerMutation,
  useGetOrganizationCardsQuery,
  useGetVerifiedAddressesQuery,
  useCreateCardMutation,
  useGetCardTransactionsQuery,
  useGetCardProductsQuery,
  useGetOrganizationCardQuery,
  useChangeVerifiedAddressesPostOnboardingMutation,
  useChangeVerifiedAddressesMutation,
  useGenerateVerifiedAddressesMutation,
  useAddVerifiedAddressesMutation,
  useGenerateVerifiedAddressesPostOnboardingMutation,
  useAddVerifiedAddressesPostOnboardingMutation,
  useGetCardFundingAccountQuery,
  useDeleteVerifiedWalletMutation,
  useCreateVerifiedWalletMutation,
  useFreezeCardMutation,
  useUnfreezeCardMutation,
  useCancelCardMutation,
  useUpdateCardMutation,
  useGetOrganizationCardDetailQuery,
  useSyncCardTransactionsMutation,
  useGenerateWithdrawalMutation,
  useSignGeneratedWithdrawalMutation,
  useCreateCardAnnotationMutation,
  useDeleteCardAnnotationMutation,
  useAppendTransactionAnnotationMutation,
  useRemoveTransactionAnnotationMutation
} = cardApi
